import React from "react"
import Header from "./header"
import Footer from "./footer"
import styles from "./layout.module.css"

const Layout = ({ children }) => (
  <div className={styles.outer}>
    <Header />
    {children}
    <Footer />
  </div>
)

export default Layout
