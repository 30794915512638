import React from "react"
import styles from "./footer.module.css"

const Footer = () => (
  <>
    <hr className={styles.line} />

    <p className={styles.message}>
      For inquiries, conversation, or suggestions{" "}
      <a href="mailto:livingstonstandup@gmail.com">Contact Us</a>
    </p>
  </>
)

export default Footer
