import React, { useState } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import menu from "../images/menu.svg"
import closeIcon from "../images/closeIcon.svg"
import styles from "./header.module.css"

const pages = [
  {
    name: "About",
    url: "/about",
  },
  {
    name: "Community",
    url: "/community",
  },
  {
    name: "Donate",
    url: "/donate",
  },
  {
    name: "Write",
    url: "/write",
  },
  {
    name: "Petition",
    url: "/petition",
  },
]

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  const [isOpen, setOpen] = useState(false)
  const open = () => setOpen(true)
  const close = () => setOpen(false)

  const sidenavClassName = [styles.sidenav, isOpen ? styles.open : null]
    .filter(Boolean)
    .join(" ")

  return (
    <header className={styles.outer}>
      <h3 className={styles.title}>
        <Link to="/" className={styles.homeLink}>
          {" "}
          {data.site.siteMetadata.title}{" "}
        </Link>
      </h3>
      <nav className={styles.nav}>
        <ul className={styles.list}>
          {pages.map(page => (
            <li key={page.name}>
              <Link
                to={page.url}
                className={styles.link}
                activeClassName={styles.linkActive}
              >
                {page.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <button className={styles.menuButton} onClick={open}>
        <img src={menu} alt="menu button" />
      </button>
      {isOpen ? <div className={styles.overlay} onClick={close} /> : null}
      <div className={sidenavClassName}>
        <button className={styles.closeMenu} onClick={close}>
          <img src={closeIcon} alt="close menu" />
        </button>
        <ul className={styles.sidenavLinks}>
          {pages.map(page => (
            <li key={page.name}>
              <Link
                to={page.url}
                className={styles.link}
                activeClassName={styles.linkActive}
              >
                {page.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </header>
  )
}

export default Header
