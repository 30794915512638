import React from "react"
import Layout from "../components/layout"
import ExternalLink from "../components/externalLink"
import aboutPage from "../images/aboutPage.png"
import styles from "./about.module.css"

const About = () => (
  <Layout>
    <div className={styles.row}>
      <div className={styles.imageColumn}>
        <img className={styles.image} src={aboutPage} alt="" />
      </div>
      <div className={styles.contentColumn}>
        <p className="text-underline">Black Lives Matter</p>

        <p>
          Black and Brown people are disproportionately targeted by the police.
          The call to action in this moment is to{" "}
          <ExternalLink to="https://www.thecut.com/2020/06/what-does-defund-the-police-mean-the-phrase-explained.html">
            <strong>defund and reimagine policing</strong>
          </ExternalLink>{" "}
          due to the recent and ongoing string of murders of Black people in
          this country.
        </p>

        <p>
          <strong>What does this have to do with Livingston?</strong>
        </p>

        <p>
          Livingston is where we grew up, where we have memories of bike riding
          to the pool, walking to the library to study, playing at school
          playgrounds.
        </p>

        <p>
          <strong>The issue?</strong>
        </p>

        <p>
          Livingston was red lined, meaning that Black people were legally
          denied mortgages for houses in our town for decades. This is true for
          all suburbs in the United States that had a big period of growth
          between the 1930s and 1950s. The legacy of red-lining is obvious when
          you notice how segregated Essex County is. Think about how in less
          than 15 minutes in the car you are in a predominantly Black
          neighborhood. The Oranges. Newark. Irvington. Elizabeth. This is
          policy and practice. Segregation. Inequity. It was built like this.
          Systemic racism has sunk its roots into the soil of this town and
          county.
        </p>

        <p>
          and now it is way past due to but <strong>TIME</strong> to stand up
          for what is right. How?
        </p>

        <p>
          Livingston! Stand up, open your wallets, your purses, and{" "}
          <strong>DONATE</strong>.
        </p>

        <p>Talk to your family.</p>

        <p>Attend protests.</p>

        <p>Write to your elected officials. </p>

        <p>Sign petitions.</p>

        <p>Stand up. Fight back.</p>
      </div>
    </div>
  </Layout>
)

export default About
